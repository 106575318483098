import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { BlogPost } from '../../components/post';
import MainFeaturedPost from './components/MainFeaturedPost';
import { useLocation } from 'react-router-dom';
import { ShimmerPostItem } from "react-shimmer-effects";
import { Pagination, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { FormatBold } from '@mui/icons-material';

const Home = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const [noOfPages, setNoOfPages] = useState(1);
    const pageSize: number = (searchParams.get('pageSize') as unknown as number) ?? 6;
    const page: number = searchParams.get('page') as unknown as number ?? 1;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const url = `https://cautious-lion-threads.cyclic.app/article/articles?pageSize=${pageSize}&page=${page}`;
            try {
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    setArticles(
                        data.result.map((article: any) => ({
                            title: article.title,
                            date: article.created_at,  // You can use the actual date from your data if needed
                            description: article.summary,
                            image: article.image_url,
                            imageLabel: article.title,
                            pageId: article.page_id
                        }))
                    );
                    setNoOfPages(Math.ceil((data.count as number) / pageSize));
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [pageSize, page]);
    return (
        <main>
            <MainFeaturedPost />
            <Alert severity="info" sx={{ marginBottom: 2 }} >
                <Typography><strong>📢 Call for Contributions!</strong></Typography>
                <Typography>
                    Dear Esteemed Bloggers and Writers, we invite you to be a part of Legal Tablet's distinguished blog. Share your passion for law by contributing original content (up to 2500 words). Are you interested in showcasing your insights? 
                    <a href="https://forms.gle/jhYgRC5aPXjxQZKfA"><strong> Join us! 🚀</strong></a>
                </Typography>
            </Alert>
            <Grid container padding={1} spacing={4}>

                {!loading ? (
                    articles.map((post, index) => (
                        <BlogPost key={index} post={post} />
                    ))
                ) : (
                    Array.from({ length: pageSize }, (_, index) => (
                        <Grid item key={index} xs={12} lg={4} md={6}>
                            <ShimmerPostItem card title text />
                        </Grid>
                    ))
                )}
            </Grid>
            <div className="flex justify-center items-center h-full mt-10">
                <Pagination onChange={(event, pageNo) => {
                    navigate("?page=" + pageNo);
                }} count={noOfPages} variant="outlined" color="primary" />
            </div>
        </main>
    );
}

export default Home;
