import Main from '../Main';
import Sidebar from '../Sidebar';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import RecentArticle from '../../models/recent_articles';

const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'LinkedIn', url: "https://www.linkedin.com/groups/14391079", icon: LinkedinIcon },
    { name: 'Facebook', url: "https://www.facebook.com/share/p/eiqsoc7mbKv3MV4t/?mibextid=xfxF2i", icon: FacebookIcon },
  ],
};

interface Heading {
  title: string;
  image_url: string;
}

const Article = () => {
  const [loading, setLoading] = useState(true);
  const [headingLoading, setHeadingLoading] = useState(true);
  const [headingInfo, setHeadingInfo] = useState<Heading>();
  const [articleContent, setArticleContent] = useState([""]);
  const [recentBlog, setRecentBlogs] = useState<ReadonlyArray<RecentArticle>>([]);
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const pageId = parts[parts.length - 1];
  const fetchData = async () => {
    setLoading(true);

    const url = `https://cautious-lion-threads.cyclic.app/article/content?pageId=${pageId}`;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.text();
        setArticleContent([data]);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  const memoizedFetchData = useMemo(() => fetchData, [pageId]); // Memoize the function

  const fetchHeading = async () => {
    setHeadingLoading(true);
    const url = `https://cautious-lion-threads.cyclic.app/article/id?pageId=${pageId}`;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setHeadingInfo(data["info"]);
        // Assuming `data` is of type any, you may want to replace any with a more specific type
        const recentBlogs: RecentArticle[] = (data["recent_blogs"] as any[]).map((article: any) => ({
          title: article["title"] || "", page_id: article["page_id"] || ""
        }));
        setRecentBlogs(recentBlogs);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHeading();
    memoizedFetchData(); // Call the memoized function
  }, [memoizedFetchData]); // Include memoized function in dependency array

  return (
    <Grid container spacing={5} sx={{ mt: '0px' }}>
      <Main title={headingInfo?.title} image={headingInfo?.image_url} posts={articleContent} />
      <Sidebar
        title={sidebar.title}
        description={sidebar.description}
        archives={recentBlog}
        social={sidebar.social}
      />
    </Grid>
  );
}

export default Article;
