import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/1_home/home';
import LegalUpdates from './pages/2_legal_updates/legal_updates';
import RecentJudgements from './pages/3_recent_judgement/recent_judgement';
import CourtCaseTracker from './pages/4_court_case_tracker/court_case_tracker';
import ContactDetails from './pages/5_contact_details/contact_details';
import { Divider } from '@mui/material';
import Article from './pages/article/article';
import Header from './components/Header';
import Footer from './components/Footer';


const sections = [
  { title: 'Home', url: '/' },
  { title: 'Legal Updates', url: 'legal-updates' },
  { title: 'Recent Judgements', url: 'recent-jugdements' },
  { title: 'Court Case Tracker', url: 'court-case-tracker' },
  { title: 'Contact Details of Public Authorities', url: 'contact-details' },
  { title: 'Contact Us', url: '/' },
];


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Router>
        <Container className='max-w-screen-lg min-h-screen  flex flex-col'>
          <Header title="Legal Tablet" sections={sections} />
          <Divider />
          <div className="flex-1 overflow-y-auto" style={{ minHeight: '100vh' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/legal-updates" element={<LegalUpdates />} />
              <Route path="/recent-jugdements" element={<RecentJudgements />} />
              <Route path="/court-case-tracker" element={<CourtCaseTracker />} />
              <Route path="/contact-details" element={<ContactDetails />} />
              <Route path="/article/:id" element={<Article />} />
            </Routes>
          </div>
          <Footer
            title="The Legal Tablet"
            description="For contact, please refer to thelegaltablet@gmail.com"
          />

        </Container>
      </Router>

    </ThemeProvider>
  );
}
