import FullLengthPost from "../../components/FullLengthPost";
import { Pagination } from '@mui/material';
import { useNavigate } from "react-router-dom";

const RecentJudgements = () => {
    const navigate = useNavigate();
    return (
        <main className="pt-4">

             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <FullLengthPost
                title="Pramod Singh Kirar VS State of Madhya Pradesh & Ors (2022)"
                subtitle="By Admin, 1 day ago"
                summary="Citation CIVIL APPEAL NOS. 8934-8935 OF 2022 Date of Judgement 02 December 2022 Court Supreme Court of India Case Type Code of Civil Procedure, 1908 (CPC) Appellant Pramod Singh Kirar Respondent State of Madhya Pradesh and Ors. Bench M. R. ShahC. T. Ravikumar Referred Avtar Singh Vs. Union of IndiaRead more…"
                imageUrl="https://legalvidhiya.com/wp-content/uploads/2023/11/Screenshot-2023-11-26-190538.png"
            />
             <div className="flex justify-center items-center h-full mt-10">
                <Pagination onChange={(event, pageNo) => {
                    navigate("?page=" + pageNo);
                }} count={2} variant="outlined" color="primary" />
            </div>
        </main>
    );
}

export default RecentJudgements;