import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ShimmerPostItem } from "react-shimmer-effects";
import { Chip } from '@mui/material';

export interface BlogPostProps {
    post: {
        date: string;
        description: string;
        image: string;
        imageLabel: string;
        title: string;
        pageId:string;
    };
}

export const BlogPost = (props: BlogPostProps) => {
    const { post } = props;
    if (post === null) {
        return (
            <Grid item xs={12} lg={4} md={6}>
                <ShimmerPostItem card title text />
            </Grid>
        );
    } else
        return (
            <Grid item xs={12} lg={4} md={6}>
                <CardActionArea component="a" href={`/article/${post!.pageId}`}>
                    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                            component="img"
                            sx={{ height: 250 }}
                            image={post!.image}
                            alt={post!.imageLabel}
                        />
                        <CardContent sx={{ flex: 1 }}>
                            <Typography component="h2" variant="h5" sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 1, // Limit the number of lines
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                {post!.title}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                                {post!.date}
                            </Typography>
                            <Typography variant="subtitle1" paragraph sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2, // Limit the number of lines
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                {post!.description}
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                Continue reading...
                            </Typography>
                        </CardContent>

                    </Card>
                </CardActionArea>
            </Grid>
        );
};