import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { fetchTopNews } from '../../../api/get_burning_news_api';


interface MainFeaturedPostProps {
  description?: string;
  image?: string;
  imageText?: string;
  linkText?: string;
  title?: string;
}

export default function MainFeaturedPost() {
  const [mainFeaturedPost, setMainFeaturedPost] = useState<MainFeaturedPostProps | undefined>(undefined);
  const fetchPageWithApis = async () => {
    const response = (await fetchTopNews())[0];
    setMainFeaturedPost({
      title: response.title,
      description: response.summary,
      image: response.image_url,
      imageText: 'main image description',
      linkText: 'Continue reading…',
    })
  }
  useEffect(() => {
    fetchPageWithApis();
  }, []);

  return (
    <div>
      {mainFeaturedPost ? (
        // Render mainFeaturedPost if it has data
        <Paper
          sx={{
            position: 'relative',
            backgroundColor: 'grey.800',
            color: '#fff',
            mb: 4,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${mainFeaturedPost.image || ''})`,
          }}
        >
          {/* Increase the priority of the hero background image */}
          {<img style={{ display: 'none' }} src={mainFeaturedPost.image} alt={mainFeaturedPost.imageText} />}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,.3)',
            }}
          />
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, lg: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography component="h1" variant="h4" className='text-sm' color="inherit" gutterBottom>
                  {mainFeaturedPost.title || ''}
                </Typography>
                <Typography variant="h6" color="inherit" paragraph>
                  {mainFeaturedPost.description || ''}
                </Typography>
                <Link variant="h6" href="#">
                  {mainFeaturedPost.linkText || ''}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <ShimmerThumbnail height={500} rounded />
      )}
    </div>
  );
}
