import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
interface FullLengthPostProps {
    title: string;
    subtitle: string;
    summary: string;
    imageUrl: string;
}

const FullLengthPost: React.FC<FullLengthPostProps> = ({
    title,
    subtitle,
    summary,
    imageUrl,
}) => (
    <Grid item xs={12} px={2} py={1} md={6}>
        <Link component="h2" variant="h6">
            {title}
        </Link>

        <div className='flex mt-2' >
            <Card sx={{ width: 120, height: 120 , display: { xs: 'none', sm: 'block' }}}>
                <CardMedia
                    component="img"
                    sx={{ height: "100%", width: "100%" }}
                    image={imageUrl}
                    alt={title}
                />
            </Card>
            <div className='sm:ml-4 ' style={{ flex: 1 }}>
                <Typography variant="subtitle1" paragraph>
                    {summary}
                </Typography>

                <Typography variant="subtitle1" color="text.secondary">
                    {subtitle}
                </Typography>
        <Divider/>
            </div>
        </div>
    </Grid>
);

export default FullLengthPost;
