import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Drawer, Icon } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
}

export default function Header(props: HeaderProps) {
  const { sections, title } = props;
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <React.Fragment>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {sections.map((text, index) => (
              <ListItem key={text.title} disablePadding>
                <ListItemButton component={Link} href={text.url}>

                  <ListItemText primary={text.title} />
                </ListItemButton>
              </ListItem>

            ))}
          </List>
          
        </Box>
      </Drawer>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <span className="material-symbols-rounded" onClick={toggleDrawer(true)}>
          <div className='block md:hidden'>

          <Icon>
            menu
          </Icon>
          </div>
        </span>

        <Typography
          component="h2"
          variant="h6"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
          {title}
        </Typography>
        <Button href='https://play.google.com/store/apps/details?id=com.legaltablet.mobile' size="small">Android App</Button>

      </Toolbar>
      <div
        className='hidden md:block'
      >

        <Toolbar
          component="nav"
          variant="dense"
          sx={{ justifyContent: 'space-between', overflowX: 'auto' }}
        >
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              sx={{ p: 1, flexShrink: 0 }}
            >
              {section.title}
            </Link>
          ))}
        </Toolbar>
      </div>
    </React.Fragment>
  );
}
